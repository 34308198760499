html {
    scroll-behavior: smooth;
}

.list-style-cli {
    list-style-image: url("data:image/svg+xml,%0A%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cmetadata%3E Svg Vector Icons : http://www.onlinewebfonts.com/icon %3C/metadata%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M984.7,748.4c-3.5-3.6-8.1-5.3-13.6-5.3H401.2c-5.5,0-10.1,1.8-13.7,5.3c-3.6,3.6-5.3,8.1-5.3,13.6v38c0,5.5,1.8,10.1,5.3,13.6c3.6,3.6,8.1,5.3,13.7,5.3H971c5.5,0,10.1-1.8,13.6-5.3c3.6-3.6,5.3-8.1,5.3-13.6v-38C990,756.5,988.2,752,984.7,748.4z'/%3E%3Cpath d='M72.9,186.9c-4-4-8.5-5.9-13.7-5.9c-5.1,0-9.7,2-13.6,5.9l-29.7,29.7c-4,4-5.9,8.5-5.9,13.6c0,5.2,2,9.7,5.9,13.7l233.3,233.3L15.9,710.4c-4,4-5.9,8.5-5.9,13.6c0,5.2,2,9.7,5.9,13.7l29.7,29.7c4,4,8.5,5.9,13.6,5.9s9.7-2,13.7-5.9l276.6-276.6c4-4,5.9-8.5,5.9-13.7c0-5.1-2-9.7-5.9-13.6L72.9,186.9z'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.radio_as_switcher + label {
    @apply bg-gray-100 transition-all text-revenue border-revenue border cursor-pointer px-4 py-2 mx-0 border-r-0;
}

.radio_as_switcher:checked + label {
    @apply bg-revenue text-white ;
}

.radio_as_switcher:first-of-type + label {
    @apply rounded-l-lg;
}

.radio_as_switcher:last-of-type + label {
    @apply rounded-r-lg border-r;
}

.pfp_forecast_already_added {
    cursor: not-allowed;
    @apply bg-gray-200;
}


/* Rules for data entry tables ( Revenue entry and Billings Entry */
.bg-account {
    @apply bg-purple-200;
}

.bg-recurring {
    @apply bg-indigo-200;
}

.bg-readonly {
    @apply bg-indigo-100;
}

.bg-data-entry {
    @apply bg-white;
}

.busnav-btn {
    @apply bg-white block rounded box-border p-3 flex h-12 hover:bg-dark_gray2 hover:text-white transition-all;
}

// for projection view hide rows that are not account totals, also hide revenue totals
.projection-mode {
    tr {
        &.bg-readonly,
        &.bg-data-entry,
        &.revenue-row {
            @apply hidden;
        }
    }
    .add-flow-btn {
        @apply hidden;
    }
}

.allocation-negative-value {
    @apply text-red-600 bg-red-100 border-red-400;
}

.allocation-highlight {
    animation: pulseFrame 1s linear;
}
/* my css */
.busnav-btn {
    padding: 0.4rem 0.75rem;
}

.radio_as_switcher + label{
    padding: 0.5rem;
    padding-top: 0.25rem;
}
  
@keyframes pulseFrame {
    from {
        background-color: transparent;
    }
    to {
        @apply bg-yellow-200;
    }
}
